
// Code
// ––––––––––––––––––––––––––––––––––––––––––––––––––

code {
	background: $color-tertiary;
	border-radius: .4rem;
	font-size: 86%;
	margin: 0 .2rem;
	padding: .2rem .5rem;
	white-space: nowrap;
}

pre {
	background: $color-tertiary;
	border-left: .3rem solid $color-primary;
	overflow-y: hidden;

	& > code {
		border-radius: 0;
		display: block;
		padding: 1rem 1.5rem;
		white-space: pre;
	}
}