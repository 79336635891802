  
$unit-tiny: 6px;
$unit-small: $unit-tiny * 2;
$unit: $unit-small * 2;
$unit-large: $unit * 2;
$unit-huge: $unit-large * 2;

$font-weight-normal: 300;
$font-weight-bold: 400;
$font-family: "Poppins", "Oswald", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$text-color: #fff;
$header-text-color: $text-color;
$background-color: #eee;

$landing-image: './img_palju2.jpg'