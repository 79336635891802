@import "../styles/Color";

.c-feedback {
  padding: 2em;
  margin-top: .25em;
  margin-bottom: .5em;

  &.is-error {
    background-color: mix($color-negative, #fff, 6%);
    color: $color-negative;
  }

  &.is-success {
    background-color: mix($color-success, #fff, 6%) ;
    color: $color-success;
  }
}