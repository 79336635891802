
// Color
// ––––––––––––––––––––––––––––––––––––––––––––––––––

$color-initial: #fff !default;
$color-primary: #59323C !default;
$color-secondary: #260126 !default;
$color-tertiary: #f4f5f6 !default;
$color-quaternary: #d1d1d1 !default;
$color-quinary: #e1e1e1 !default;
$color-negative: #F05050 !default;
$color-success: #4DBD74 !default;