
header
{
	height: 100vh;
    background-image: url($landing-image);
	display: flex;
	flex-direction: column;
	align-items: center;
    background-size: cover;
    background-position: center;
	justify-content: center;
    margin-bottom: 30px;
    color: #fff;

  h1 {
    font-size: 3rem;
    padding: 1em;
  }

  p {
    padding-left: 1em;
    padding-right: 1em;
  }

  .down {
    color: #fff;
    position: absolute;
    top:85vh;
    size: 40px;
    padding: 20px;
    font-size: 2.5em;
    animation: action 1s infinite  alternate;
    cursor: pointer;
  }
}

@keyframes action {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
#down:hover
{
	cursor: pointer;
	//background-color: #eee;
	opacity: 0.8;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding-top: 1.8vw;
  padding-bottom: 1.8vw;
  padding-left: 4vw;
  padding-right: 4vw;
}