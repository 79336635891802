@import "../styles/_Color";

main {
  width: 90%;
  max-width: 100%;
  /*padding: 15px;*/
  //height: 100vh;
  background-color: #fff;

  h2 {
    color: $color-primary;
  }

  * {
    color: $color-primary;
  }
}

footer {
  text-align: center;
  padding-top: .5em;
  padding-bottom: 1em;
  background-color: #8C6954;
}

.o-list-bare {
  list-style: none;
}